import React, { useState } from 'react';
import { TextField, Button, Box, Grid, Typography } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import * as emailjs from "@emailjs/browser";

function ContactForm({ price, power, location }) {
  const { handleSubmit, control, reset, formState: { errors } } = useForm();
  const [disabled, setDisabled] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const onSubmit = async (data) => {
    const { user_name, user_email, contact_number, message } = data;

    try {
      setDisabled(true);

      const templateParams = {
        user_name,
        user_email,
        contact_number,
        message,
        price,
        power,
        location: location === 'roof' ? 'Na dachu' : 'Na ziemii'
      };

      await emailjs.send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_PUBLIC_KEY,
      );

      reset();
      setIsSubmitted(true);
    } catch (e) {
      console.log(e);
      setDisabled(false);
    }
  };

  return (
    <Box p={2} pb={0}>
      <Typography variant="h5" gutterBottom align="center" pb={2}>
        Zamów już dziś
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid container spacing={2}>
          {/* Pole Imię */}
          <Grid item xs={12} sm={6}>
            <Controller
              name="user_name"
              control={control}
              rules={{
                required: "Proszę podać imię",
                maxLength: {
                  value: 30,
                  message: "Proszę użyć maksymalnie 30 znaków"
                }
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Imię"
                  size="small"
                  variant="outlined"
                  fullWidth
                  error={!!errors.user_name}
                  helperText={errors.user_name ? errors.user_name.message : ''}
                />
              )}
            />
          </Grid>
          {/* Pole Email */}
          <Grid item xs={12} sm={6}>
            <Controller
              name="user_email"
              control={control}
              rules={{
                required: "Proszę podać adres e-mail",
                pattern: {
                  value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  message: "Proszę podać prawidłowy adres e-mail"
                }
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Adres e-mail"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.user_email}
                  helperText={errors.user_email ? errors.user_email.message : ''}
                />
              )}
            />
          </Grid>
          {/* Pole Numer kontaktowy */}
          <Grid item xs={12}>
            <Controller
              name="contact_number"
              control={control}
              rules={{
                required: "Proszę podać numer kontaktowy",
                maxLength: {
                  value: 9,
                  message: "Numer powinien mieć maksymalnie 9 cyfr"
                }
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Numer kontaktowy"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.contact_number}
                  helperText={errors.contact_number ? errors.contact_number.message : ''}
                />
              )}
            />
          </Grid>
          {/* Pole Wiadomość */}
          <Grid item xs={12}>
            <Controller
              name="message"
              control={control}
              rules={{ required: "Proszę podać wiadomość" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Dodatkowe informacje"
                  variant="outlined"
                  size="small"
                  fullWidth
                  multiline
                  rows={3}
                  error={!!errors.message}
                  helperText={errors.message ? errors.message.message : ''}
                />
              )}
            />
          </Grid>
        </Grid>

        <Box mt={2} textAlign="center">
          {isSubmitted ? (
            <Typography
              variant="body2"
              align="center"
              style={{ backgroundColor: '#4CAF50', color: '#fff', padding: '12px', borderRadius: '4px' }}
            >
              Odebraliśmy Twoje zapytanie. Skontaktujemy się z Tobą wkrótce.
            </Typography>
          ) : (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={disabled}
              style={{ backgroundColor: '#fccc44', color: '#000', marginTop: '16px' }}
            >
              Wyślij zapytanie
            </Button>
          )}
        </Box>
      </form>
    </Box>
  );
}

export default ContactForm;
