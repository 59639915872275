import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Radio, RadioGroup, FormControlLabel, FormControl } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import ContactForm from "./ContactForm";

function SectionTwo() {
  const [showContactForm, setShowContactForm] = useState(false);
  const [price, setPrice] = useState(null);
  const { control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      power: '',
      location: '',
      lastCleaning: ''
    }
  });

  const calculatePrice = (power, location, lastCleaning) => {
    const squareMeters = power * 5.5;  // 1 kWp to 5.5 m²
    let pricePerSquareMeter;

    // Cennik na podstawie mocy instalacji
    if (power <= 10) {
      pricePerSquareMeter = 13.2;
    } else if (power <= 30) {
      pricePerSquareMeter = 11;
    } else if (power <= 50) {
      pricePerSquareMeter = 9;
    } else {
      return "Cena ustalana indywidualnie dla instalacji powyżej 50 kWp.";
    }

    let basePrice = squareMeters * pricePerSquareMeter;

    // Dodaj 10% za dach
    if (location === 'roof') {
      basePrice *= 1.10;
    }

    // Dodaj procent za czas od ostatniego mycia
    if (lastCleaning === '2-4') {
      basePrice *= 1.10;
    } else if (lastCleaning === '5+') {
      basePrice *= 1.15;
    }

    return Math.round(basePrice);
  };

  const onSubmit = (data) => {
    const finalPrice = calculatePrice(parseFloat(data.power), data.location, data.lastCleaning);
    setPrice(finalPrice);
    setShowContactForm(true);
  };

  return (
    <Box display="flex" justifyContent="center" flexDirection="column">
      <Box flex={1}>
        {showContactForm ? (
          <>
            <Typography variant="h6">
              Szacowana cena usługi: <span style={{ fontWeight: 'bold', fontSize: '1.25rem' }}>{price} zł</span>
            </Typography>
            <ContactForm price={price} power={control._formValues.power} location={control._formValues.location} />
          </>
        ) : (
          <>
            <Typography variant="h5" style={{ fontWeight: 'bold'}} gutterBottom align="center" pb={1}>Kalkulator wyceny:</Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box display="flex" flexDirection="column" gap={2} alignItems="center">

                {/* Moc instalacji */}
                <Controller
                  name="power"
                  control={control}
                  rules={{
                    required: "Moc instalacji jest wymagana",
                    pattern: {
                      value: /^[1-9]\d*$/,
                      message: "Wprowadź dodatnią liczbę całkowitą"
                    }
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Moc instalacji (kWp)"
                      variant="outlined"
                      error={!!errors.power}
                      helperText={errors.power ? errors.power.message : ''}
                      fullWidth
                      style = {{width: '200px'}}
                    />
                  )}
                />

                {/* Lokalizacja instalacji */}

                <div style={{display: "flex", width: "100%", justifyContent: "center", gap: "4rem"}}>
                  <Controller
                    name="location"
                    control={control}
                    rules={{ required: "Wybierz położenie paneli" }}
                    render={({ field }) => (
                      <FormControl component="fieldset" error={!!errors.location}>
                        <Typography variant="body1" pb={1}>Lokalizacja paneli:</Typography>
                        <RadioGroup {...field} aria-label="location" name="location" flexDirection="column">
                          <FormControlLabel value="roof" control={<Radio />} label="Na dachu" />
                          <FormControlLabel value="ground" control={<Radio />} label="Na ziemii" />
                        </RadioGroup>
                        {errors.location && (
                          <Typography variant="body2" color="error">
                            {errors.location.message}
                          </Typography>
                        )}
                      </FormControl>
                    )}
                  />

                  {/* Czas od ostatniego mycia */}
                  <Controller
                    name="lastCleaning"
                    control={control}
                    rules={{ required: "Wybierz czas od ostatniego mycia" }}
                    render={({ field }) => (
                      <FormControl component="fieldset" error={!!errors.lastCleaning}>
                        <Typography variant="body1" pb={1}>Czas od ostatniego mycia:</Typography>
                        <RadioGroup {...field} aria-label="lastCleaning" name="lastCleaning" flexDirection="column">
                          <FormControlLabel value="0-2" control={<Radio />} label="Do 2 lat" />
                          <FormControlLabel value="2-4" control={<Radio />} label="2-4 lata" />
                          <FormControlLabel value="5+" control={<Radio />} label="5+ lat" />
                        </RadioGroup>
                        {errors.lastCleaning && (
                          <Typography variant="body2" color="error">
                            {errors.lastCleaning.message}
                          </Typography>
                        )}
                      </FormControl>
                    )}
                  />
                </div>


                <Box display="flex" justifyContent="center">
                  <Button
                    type="submit"
                    variant="contained"
                    style={{ backgroundColor: '#fccc44', color: '#000', width: '200px' }}
                  >
                    Oblicz
                  </Button>
                </Box>

              </Box>
            </form>
          </>
        )}
      </Box>
    </Box>
  );
}

export default SectionTwo;
